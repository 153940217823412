<script setup lang="ts">
import type { DirectusUsers } from '@laast-io/types'

import { joinURL } from 'ufo'
import { useAsyncCustomerMessagesUnread } from '~/composables/data/inbox/customers'

const { $openTidio } = useNuxtApp()
const { isSidebarOpenMobile, isSidebarCollapsed } = useLayoutContext()
const panels = usePanels()

const { isSuperAgent, user } = useDirectusAuth()

const lastConversationSelection = useSessionStorage<string>(
  'inbox.lastConversationSelection',
  '',
)
const { data } = useAsyncCustomerMessagesUnread()

const unreadState = useState('total_messages_unread', () => 0)
watch(() => data.value, (unread) => {
  unreadState.value = unread || 0
}, { immediate: true })
</script>

<template>
  <div
    class="fixed left-0 top-0 z-50 h-full w-64 bg-cream-200 transition-all duration-150 dark:bg-muted-800 md:translate-x-0"
    :class="[
      isSidebarOpenMobile ? 'translate-x-0' : '-translate-x-full',
      isSidebarCollapsed && !isSidebarOpenMobile ? 'w-20 sm:w-16' : '',
    ]"
  >
    <div
      class="relative flex h-[70px] items-center justify-between"
      :class="isSidebarCollapsed ? 'lg:px-0' : 'px-6'"
    >
      <LayoutSidebarLogo
        width="90"
        height="40"
        :collapsed="isSidebarCollapsed"
        @click="() => {
          isSidebarCollapsed = !isSidebarCollapsed
          isSidebarOpenMobile = false
        }"
      />
      <div class="absolute -end-4 top-2 scale-90" :class="isSidebarCollapsed ? '' : ''">
        <LayoutHamburger />
      </div>
      <LayoutHamburgerMobile class="md:hidden" />
    </div>
    <div
      class="relative flex h-[calc(100%_-_70px)] flex-col"
      :class="
        isSidebarCollapsed ? 'pb-2' : 'px-6 py-2'
      "
    >
      <LayoutSidebarCreateDropdown
        class="mb-6"
        :class="[
          isSidebarCollapsed ? 'px-2' : 'pe-3',
        ]"
      />
      <div
        class="relative mb-8 space-y-1"
        :class="[
          isSidebarCollapsed ? 'px-2' : 'nui-slimscroll overflow-y-scroll pe-2',
        ]"
      >
        <LayoutSidebarItem
          :item="{
            to: '/',
            name: 'Kanban',
            icon: {
              name: 'ph:square-half-duotone',
              class: 'size-5',
            },
          }"
        />
        <LayoutSidebarItem
          :item="{
            to: '/agenda',
            name: 'Calendrier',
            icon: {
              name: 'ph:calendar-dots-duotone',
              class: 'size-5',
            },
          }"
        />
        <LayoutSidebarItem
          class="group"
          :item="{
            to: joinURL('/inbox', lastConversationSelection),
            activePath: '/inbox',
            name: 'Messagerie',
            icon: {
              name: 'ph:chat-duotone',
              class: 'size-5',
            },
          }"
        >
          <template #end>
            <span
              v-if="unreadState"
              class=" absolute bottom-1 left-6 flex h-4 items-center rounded-full bg-muted-900 px-1 text-[0.65rem] font-bold text-white transition-colors duration-300 dark:bg-muted-100 dark:text-muted-900"
            >
              <span v-if="unreadState < 9">{{ unreadState }}</span>
              <span v-else>9+</span>
            </span>
          </template>
        </LayoutSidebarItem>
        <LayoutSidebarItem
          :item="{
            activePath: '/catalog',
            name: 'Catalogue',
            icon: {
              name: 'ph:stack-duotone',
              class: 'size-5',
            },
            children: [
              {
                to: '/catalog/devices',
                name: 'Appareils',
                icon: {},
              },
              {
                to: '/catalog',
                name: 'Services',
                icon: {},
              },
              {
                to: '/catalog/spare-parts',
                name: 'Pièces détachées',
                icon: {},
              },
              {
                to: '/catalog/items',
                name: 'Produits',
                icon: {},
              },
              {
                to: '/catalog/out-of-stocks',
                name: 'Ruptures',
                icon: {},
              },
            ],
          }"
        />

        <LayoutSidebarItem
          :item="{
            to: '/customers',
            name: 'Clients',
            icon: {
              name: 'ph:users-duotone',
              class: 'size-5',
            },
          }"
        />
        <LayoutSidebarItem
          :item="{
            to: '/orders',
            name: 'Ventes',
            icon: {
              name: 'ph:seal-check-duotone',
              class: 'size-5',
            },
          }"
        />

        <LayoutSidebarItem
          :item="{
            to: '/dashboard',
            activePath: '/dashboard',
            name: 'Statistiques',
            icon: {
              name: 'ph:chart-pie-slice-duotone',
              class: 'size-5',
            },
          }"
        />
        <LayoutSidebarItem
          v-if="isSuperAgent"
          :item="{
            activePath: '/admin',
            name: 'Administration',
            icon: {
              name: 'ph:gear-duotone',
              class: 'size-5',
            },
            children: [
              {
                to: '/admin/settings',
                name: 'Paramètres',
                icon: {},
              },
              {
                to: '/admin/agents',
                name: 'Agents',
                icon: {},
              },
              {
                to: '/admin/import',
                name: 'Imports',
                icon: {},
              },
            ],
          }"
        />
      </div>
      <div
        class="relative z-20 mt-auto space-y-1"
        :class="[
          isSidebarCollapsed ? 'px-2' : 'pe-2',
        ]"
      >
        

        
        <LayoutSidebarItem
          :item="{
            name: 'Aide & Suggestions',
            onClick: () => $openTidio?.(),
            icon: {
              name: 'ph:lifebuoy-duotone',
              class: 'size-5',
            },
          }"
        />
        <button
          type="button"
          class="group nui-focus flex w-full cursor-pointer items-center rounded-lg p-2 text-cream-800 transition-colors duration-300 hover:text-cream-900 dark:text-cream-400/80 dark:hover:text-cream-200"
          :class="
            isSidebarCollapsed
              ? 'w-full justify-center'
              : 'gap-3'
          "
          @click="() => panels.push('account', {})"
        >
          <AppAgentAvatar
            size="xs"
            class="shrink-0 ring-1 ring-muted-300 ring-offset-4 transition-all duration-150 group-hover:ring-cream-500 dark:ring-muted-600 dark:ring-offset-muted-600"
            :user="(user as DirectusUsers)"
          />
          <span
            class="font-sans text-sm"
            :class="
              isSidebarCollapsed ? 'hidden' : ''
            "
          >
            Mon Compte
          </span>
        </button>
      </div>
      
      <img
        src="/img/brand/face-glasses.svg"
        class="pointer-events-none absolute -left-4 bottom-1/3 w-full max-w-[55px] rotate-45 opacity-10 dark:invert"
        width="480"
        height="480"
        alt=""
        loading="lazy"
        decoding="async"
      >
      <img
        src="/img/brand/face-playful.svg"
        class="pointer-events-none absolute bottom-1/4 right-2 w-full max-w-[85px] rotate-[-25deg] select-none opacity-10 dark:invert"
        :class="isSidebarCollapsed ? 'hidden' : 'ltablet:hidden'"
        width="480"
        height="480"
        alt=""
        loading="lazy"
        decoding="async"
      >
      <img
        src="/img/brand/shape-zap.svg"
        class="pointer-events-none absolute right-8 top-1/3 w-full max-w-[55px] rotate-[10deg] select-none opacity-10 dark:invert"
        width="480"
        height="480"
        alt=""
        loading="lazy"
        decoding="async"
      >
      <img
        src="/img/brand/shape-spring.svg"
        class="pointer-events-none absolute w-full max-w-[65px] rotate-[40deg] select-none opacity-10 dark:invert"
        :class="
          isSidebarCollapsed
            ? 'top-12 -right-3'
            : '-top-6 right-4'
        "
        width="480"
        height="480"
        alt=""
        loading="lazy"
        decoding="async"
      >
      <img
        src="/img/brand/shape-screw.svg"
        class="pointer-events-none absolute w-full max-w-[95px] rotate-[30deg] select-none opacity-10 dark:invert"
        :class="
          isSidebarCollapsed
            ? 'bottom-16 left-0'
            : 'bottom-16 left-4'
        "
        width="480"
        height="480"
        alt=""
        loading="lazy"
        decoding="async"
      >
    </div>
  </div>
</template>
