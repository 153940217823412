<script setup lang="ts">
const props = defineProps<{
  item: any
  expanded?: boolean
}>()

const emit = defineEmits<{
  clicked: []
}>()

const route = useRoute()

const isActive = computed(() => {
  if (!props.item?.activePath) {
    return false
  }

  return route.path.startsWith(props.item.activePath)
})

const buttonRef = shallowRef<HTMLElement>()
const isOpen = ref(isActive.value)

const { isSidebarCollapsed } = useLayoutContext()

watch(isSidebarCollapsed, () => {
  isOpen.value = false
})

function onDropClick() {
  isOpen.value = !isOpen.value
  if (!isOpen.value) {
    buttonRef.value?.blur()
  }
  emit('clicked')
}
</script>

<template>
  <div class="w-full">
    <BaseDropdown
      v-if="!props.expanded"
      :classes="{
        wrapper: 'flex w-full',
        menuWrapper: 'w-full',
      }"
    >
      <template #button>
        <BaseTooltip
          :classes="{ wrapper: 'block', reference: 'block' }"
          placement="right"
          :offset="4"
        >
          <template v-if="!props.expanded" #content>
            <span>{{ item.name }}</span>
          </template>
          <button
            class="nui-focus flex w-full cursor-pointer items-center rounded-lg py-2 text-cream-800 transition-colors duration-150 hover:bg-cream-300 hover:text-cream-900 dark:text-cream-400/80 dark:hover:bg-cream-700/60 dark:hover:text-cream-200"
            :class="props.expanded ? 'gap-4 px-4' : 'px-2 justify-center'"
          >
            <Icon
              v-if="item.icon?.name"
              :name="item.icon.name"
              :class="[
                isSidebarCollapsed ? '' : 'opacity-60',
                item.icon.class,
                isActive ? 'text-primary-500' : '',
              ]"
              class="shrink-0"
            />
          </button>
        </BaseTooltip>
      </template>
      <BaseDropdownItem
        v-for="child in props.item.children"
        :key="child.to"
        :to="child.to"
      >
        {{ child.name }}
      </BaseDropdownItem>
    </BaseDropdown>
    <div v-else class="group">
      <BaseTooltip
        :classes="{ wrapper: 'block', reference: 'block' }"
        placement="right"
        :offset="4"
      >
        <template v-if="!props.expanded" #content>
          <span>{{ item.name }}</span>
        </template>
        <button
          ref="buttonRef"
          class="nui-focus flex w-full cursor-pointer items-center rounded-lg py-2 text-cream-800 transition-colors duration-150 hover:bg-cream-300 hover:text-cream-900 dark:text-cream-400/80 dark:hover:bg-cream-700/60 dark:hover:text-cream-200 lg:flex"
          :class="props.expanded ? 'gap-4 px-4' : 'px-2 justify-center'"
          @click.stop.prevent="onDropClick"
        >
          <Icon
            v-if="item.icon?.name"
            :name="item.icon.name"
            :class="[
              isSidebarCollapsed ? '' : 'opacity-60',
              item.icon.class,
              isActive ? 'text-primary-500' : '',
            ]"
            class="shrink-0"
          />
          <span
            class="block whitespace-nowrap font-sans text-sm font-semibold"
            :class="[
              isActive && 'text-black dark:text-cream-300',
              !props.expanded ? 'hidden' : 'block',
            ]"
          >
            {{ item.name }}
          </span>
          <span
            class="ms-auto items-center justify-center"
            :class="!props.expanded ? 'hidden' : 'flex'"
          >
            <Icon
              name="lucide:chevron-up"
              class="size-4 transition-transform duration-150"
              :class="!isOpen ? 'rotate-180' : ''"
            />
          </span>
        </button>
      </BaseTooltip>

      <ul
        v-if="props.expanded"
        class="relative block border-muted-200 ps-4"
        :class="{
          'max-h-0 overflow-hidden opacity-0 group-focus-within:max-h-max group-focus-within:overflow-visible group-focus-within:opacity-100':
            !isOpen,
          'max-h-max opacity-100 after:border-muted-200': isOpen,
        }"
      >
        <li
          v-for="child in props.item.children"
          :key="child.to"
          class="ms-2 border-s-2 border-cream-300 dark:border-cream-900"
        >
          <NuxtLink
            :to="child.to"
            exact-active-class="!border-primary-500 !text-primary-500"
            class="nui-focus relative -start-0.5 flex cursor-pointer items-center gap-2 border-s-2 border-transparent py-1 text-cream-800 transition-colors duration-150 hover:border-cream-600 hover:text-cream-900 dark:text-cream-400/80 dark:hover:text-cream-200"
            :class="{
              'ps-4': child.icon?.name,
              'ps-6': !child.icon?.name,
            }"
            no-prefetch
          >
            <Icon
              v-if="child.icon?.name"
              :name="child.icon.name"
              :class="child.icon.class"
              class="shrink-0"
            />
            <span
              class="whitespace-nowrap font-sans text-[0.85rem]"
              :class="[!props.expanded ? 'hidden' : 'block']"
            >
              {{ child.name }}
            </span>
          </NuxtLink>
        </li>
      </ul>
    </div>
  </div>
</template>
