<script setup lang="ts">
const props = defineProps<{
  item: any
}>()

const router = useRouter()
const { isSidebarCollapsed } = useLayoutContext()
const isOpen = computed({
  get() {
    return !isSidebarCollapsed.value
  },
  set(value) {
    isSidebarCollapsed.value = !value
  },
})

const { lg } = useTailwindBreakpoints()
function onCollapseClick() {
  if (!isOpen.value) {
    if (lg.value) {
      isOpen.value = true
    }

    // go to first child
    if (props.item.children?.[0]?.to) {
      router.push(props.item.children[0].to)
    }
  }
}
</script>

<template>
  <LayoutSidebarCollapseLinks
    v-if="item.children"
    :item="item"
    :expanded="isOpen"
    @clicked="onCollapseClick"
  />
  <div
    v-else-if="item.divider"
    class="my-3 h-px w-full border-t border-cream-200 dark:border-cream-700"
  />
  <BaseTooltip
    v-else
    :classes="{ wrapper: 'block', reference: 'block' }"
    placement="right"
    :offset="4"
  >
    <template v-if="!isOpen" #content>
      <span>{{ item.name }}</span>
    </template>
    <NuxtLink
      v-if="item.to"
      :to="item.to"
      exact-active-class="!bg-cream-300 !text-primary-500 dark:!bg-cream-500/20"
      class="nui-focus relative flex cursor-pointer items-center gap-4 rounded-lg py-2 text-cream-800 transition-colors duration-300 hover:bg-cream-300 hover:text-cream-900 dark:text-cream-400/80 dark:hover:bg-cream-700/60 dark:hover:text-cream-200"
      :class="!isOpen ? 'px-1 justify-center' : 'px-4'"
      no-prefetch
    >
      <Icon
        :name="item.icon.name"
        class="shrink-0"
        :class="[
          isSidebarCollapsed ? '' : 'opacity-60',
          item.icon.class,
        ]"
      />
      <span
        class="whitespace-nowrap font-sans text-sm font-semibold"
        :class="!isOpen ? 'hidden' : 'block'"
      >
        {{ item.name }}
      </span>
      <slot name="end" />
    </NuxtLink>
    <button
      v-else
      class="nui-focus flex w-full cursor-pointer items-center gap-4 rounded-lg py-2 text-cream-800 transition-colors duration-300 hover:bg-cream-300 hover:text-cream-900 dark:text-cream-400/80 dark:hover:bg-cream-700/60 dark:hover:text-cream-200"
      :class="!isOpen ? 'px-1 justify-center' : 'px-4'"
      @click="item.onClick"
    >
      <Icon :name="item.icon.name" class="shrink-0" :class="item.icon.class" />
      <span
        class="whitespace-nowrap font-sans text-sm"
        :class="!isOpen ? 'hidden' : 'block'"
      >
        {{ item.name }}
      </span>
      <slot name="end" />
    </button>
  </BaseTooltip>
</template>
