<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import { Field, useForm } from 'vee-validate'
import { z } from 'zod'
import { useAsyncModalInterventionRefund } from '~/composables/data/modals/intervention-refund'

const props = defineProps<{
  interventionId: string
  invoiceId: number
}>()

const zodSchema = z
  .object({
    comment: z.string(),
    type: z.union([z.literal('cancel'), z.literal('partial')]).optional(),
    status: z.union([z.literal('complete'), z.literal('archived'), z.literal('payed')]).optional(),
    services: z.array(z.object({
      id: z.string(),
      name: z.string(),
      checked: z.boolean(),
      tax_ratio: z.number(),
      amount: z.number(),
      amount_max: z.number(),
      parts: z.array(z.object({
        id: z.string(),
        name: z.string(),
        quantity: z.number(),
        restock: z.number(),
        restock_max: z.number(),
      })),
    })),
    items: z.array(z.object({
      id: z.string(),
      name: z.string(),
      checked: z.boolean(),
      is_second_hand: z.boolean(),
      tax_ratio: z.number(),
      amount: z.number(),
      amount_max: z.number(),
      quantity: z.number(),
      restock: z.number(),
      restock_max: z.number(),
    })),
    transactions: z.array(z.object({
      type: z.union([z.literal('bank'), z.literal('user_credit')]).optional(),
      amount: z.number(),
      intervention: z.string(),
      payment_method: z.string().nullable().optional(),
      customer: z.string().nullable().optional(),
    })),
  })

type FormInput = z.infer<typeof zodSchema>
const validationSchema = toTypedSchema(zodSchema)
const initialValues: FormInput = {
  comment: '',
  type: 'cancel',
  status: 'complete',
  services: [],
  items: [],
  transactions: [],
}

const {
  handleSubmit,
  setFieldValue,
  values,
} = useForm({
  validationSchema,
  initialValues,
  keepValuesOnUnmount: true,
})

const { $sentry } = useNuxtApp()
const $directus = useDirectusSdk()

const refundModal = useInterventionRefundModal()
const paymentMethods = usePaymentMethods()
const toaster = useToaster()

// const comment = ref('')
// const kind = ref<'complete' | 'partial'>('complete')

const pending = ref(false)
const {
  data: intervention,
  status: interventionStatus,
} = await useAsyncModalInterventionRefund({
  id: () => props.interventionId,
})

const invoice = computed(() => {
  return intervention.value?.invoices?.find(invoice => invoice.id === props.invoiceId)
})

watch(invoice, () => {
  if (!invoice.value) {
    return
  }

  initValues()
}, { immediate: true })

function initValues() {
  if (!invoice.value) {
    return
  }
  const services = invoice.value.details.services.map(service => ({
    id: service.id,
    name: service.name,
    checked: true,
    tax_ratio: service.tax_ratio,
    amount: substractPrices(service.total, (service.refund_total ?? 0)),
    amount_max: substractPrices(service.total, (service.refund_total ?? 0)),
    parts: service.included_spare_parts.map(part => ({
      id: part.id,
      name: part.name,
      quantity: part.quantity,
      restock: (part.quantity - (part.refund_restocked ?? 0)),
      restock_max: (part.quantity - (part.refund_restocked ?? 0)),
    })),
  }))

  const items = invoice.value.details.items.map(item => ({
    id: item.id,
    name: item.name,
    checked: true,
    is_second_hand: item.is_second_hand,
    tax_ratio: item.tax_ratio,
    amount: substractPrices(item.total, (item.refund_total ?? 0)),
    amount_max: substractPrices(item.total, (item.refund_total ?? 0)),
    quantity: item.quantity,
    restock: (item.quantity - (item.refund_restocked ?? 0)),
    restock_max: (item.quantity - (item.refund_restocked ?? 0)),
  }))

  if (invoice.value.status === 'partially_refunded') {
    setFieldValue('type', 'partial')
    setFieldValue('transactions', [])
  }
  else {
    const transactions = invoice.value.transactions.map(transaction => ({
      type: transaction.type,
      payment_method: transaction.payment_method,
      amount: transaction.amount,
      customer: transaction.customer,
      intervention: transaction.intervention,
    }))
    setFieldValue('transactions', transactions)
  }

  setFieldValue('services', services)
  setFieldValue('items', items)
}

const amountMax = computed(() => {
  return addPrices(
    (values.services?.reduce((acc, service) => service.checked ? addPrices(acc, service.amount_max) : acc, 0) ?? 0),
    (values.items?.reduce((acc, item) => item.checked ? addPrices(acc, item.amount_max) : acc, 0) ?? 0),
  )
})

const amount = computed(() => {
  return addPrices(
    (values.services?.reduce((acc, service) => service.checked ? addPrices(acc, service.amount) : acc, 0) ?? 0),
    (values.items?.reduce((acc, item) => item.checked ? addPrices(acc, item.amount) : acc, 0) ?? 0),
  )
})

const transactionsAmount = computed(() => {
  return (values.transactions?.reduce((acc, transaction) => addPrices(acc, transaction.amount), 0) ?? 0)
})

const availablePaymentMethods = computed(() => {
  return paymentMethods.items.filter(method => values.transactions?.every(transaction => transaction.payment_method !== method.id))
})

async function cancelInvoice(comment: string, status: 'complete' | 'archived' = 'complete') {
  if (!invoice.value || !intervention.value) {
    return
  }

  if (pending.value) {
    return
  }

  pending.value = true

  try {
    const invertedDetails = {
      customer: invoice.value.details.customer,
      device_model: invoice.value.details.device_model,
      services: invoice.value.details.services.map(service => ({
        id: service.id,
        name: service.name,
        total: service.total * -1,
        total_excl_discount: service.total_excl_discount !== undefined ? service.total_excl_discount * -1 : undefined,
        subtotal: service.subtotal * -1,
        subtotal_excl_discount: service.subtotal_excl_discount !== undefined ? service.subtotal_excl_discount * -1 : undefined,
        discount: service.discount,
        quantity: service.quantity,
        tax_ratio: service.tax_ratio,
        tax_total: service.tax_total * -1,
        cost_total: service.cost_total * -1,
        discount_id: service.discount_id,
        unitary_cost: service.unitary_cost * -1,
        discount_type: service.discount_type,
        unitary_price_excl_tax: service.unitary_price_excl_tax !== undefined ? service.unitary_price_excl_tax * -1 : undefined,
        unitary_price: service.unitary_price * -1,
        discount_label: service.discount_label,
        discount_total: service.discount_total * -1,
        included_spare_parts: service.included_spare_parts.map(sparePart => ({
          id: sparePart.id,
          name: sparePart.name,
          quantity: sparePart.quantity,
          reference: sparePart.reference,
        })),
      })),
      items: invoice.value.details.items.map(item => ({
        id: item.id,
        name: item.name,
        total: item.total * -1,
        total_excl_discount: item.total_excl_discount !== undefined ? item.total_excl_discount * -1 : undefined,
        discount: item.discount,
        quantity: item.quantity,
        subtotal: item.subtotal * -1,
        subtotal_excl_discount: item.subtotal_excl_discount !== undefined ? item.subtotal_excl_discount * -1 : undefined,
        reference: item.reference,
        tax_ratio: item.tax_ratio,
        tax_total: item.tax_total * -1,
        cost_total: item.cost_total * -1,
        gain_total: item.gain_total * -1,
        discount_id: item.discount_id,
        unitary_cost: item.unitary_cost * -1,
        discount_type: item.discount_type,
        unitary_price_excl_tax: item.unitary_price_excl_tax !== undefined ? item.unitary_price_excl_tax * -1 : undefined,
        unitary_price: item.unitary_price * -1,
        discount_label: item.discount_label,
        discount_total: item.discount_total * -1,
        is_second_hand: item.is_second_hand,
        tax_margin_total: item.tax_margin_total * -1,
      })),
    }

    const invertedInvoiceData = {
      comment,
      details: invertedDetails,
      type: 'credit_note',
      date_payed: new Date().toISOString(),
      intervention: invoice.value.intervention,
      customer: invoice.value.customer,
      duration: invoice.value.duration,
      item_quantity: invoice.value.item_quantity,
      item_total: invoice.value.item_total * -1,
      service_total: invoice.value.service_total * -1,
      total: invoice.value.total * -1,
      item_subtotal: invoice.value.item_subtotal * -1,
      service_subtotal: invoice.value.service_subtotal * -1,
      subtotal: invoice.value.subtotal * -1,
      item_tax_total: invoice.value.item_tax_total * -1,
      service_tax_total: invoice.value.service_tax_total * -1,
      item_tax_margin_total: invoice.value.item_tax_margin_total * -1,
      tax_total: invoice.value.tax_total * -1,
      cost_total: invoice.value.cost_total * -1,
      discount_total: invoice.value.discount_total * -1,
      gain_total: invoice.value.gain_total * -1,
      item_cost_total: invoice.value.item_cost_total * -1,
      item_discount_total: invoice.value.item_discount_total * -1,
      item_gain_total: invoice.value.item_gain_total * -1,
      service_cost_total: invoice.value.service_cost_total * -1,
      service_discount_total: invoice.value.service_discount_total * -1,
      service_gain_total: invoice.value.service_gain_total * -1,
      transactions: invoice.value.transactions?.map(transaction => ({
        amount: transaction.amount * -1,
        type: transaction.type,
        payment_method: transaction.payment_method,
        customer: transaction.customer,
        intervention: transaction.intervention,
      })) ?? [],
    }

    const userCredit = invoice.value.transactions?.find(transaction => transaction.type === 'user_credit')

    const creditNote = await $directus.request(
      createItem('invoice', invertedInvoiceData),
    )

    await $directus.request(
      createItem('invoice_references', {
        invoice_parent: invoice.value.id,
        invoice_child: creditNote.id,
      }),
    )

    await $directus.request(
      updateItem('invoice', invoice.value.id, {
        status: 'refunded',
        details: {
          ...invoice.value.details,
          services: invoice.value.details.services.map(service => ({
            ...service,
            refund_total: service.total * -1,
            included_spare_parts: service.included_spare_parts.map(sparePart => ({
              ...sparePart,
              refund_quantity: sparePart.quantity,
            })),
          })),
          items: invoice.value.details.items.map(item => ({
            ...item,
            refund_total: item.total * -1,
            refund_quantity: item.quantity,
          })),
        },
      }),
    )
    await $directus.request(
      updateItem('intervention', intervention.value.id, {
        status,
        date_payed: null,
        planned_services: intervention.value.planned_services?.map(planned => ({
          id: planned.id,
          status,
          custom_status: status,
        })),
        items: intervention.value.items?.map(item => ({
          id: item.id,
          status,
        })),
      }),
    )

    // stock management
    const partStocks = {} as Record<string, number>
    const itemStocks = {} as Record<string, number>
    if (Array.isArray(intervention.value?.planned_services)) {
      for (const planned of intervention.value.planned_services) {
        if (Array.isArray(planned.require_spare_part)) {
          for (const part of planned.require_spare_part) {
            if (typeof part.spare_part === 'object' && part.spare_part && 'id' in part.spare_part && part.quantity) {
              if (!part.spare_part.has_managed_stocks) {
                continue
              }
              partStocks[part.spare_part.id] ??= part.spare_part.in_stock
              partStocks[part.spare_part.id] += part.quantity
            }
          }
        }
      }
    }

    if (Array.isArray(intervention.value?.items)) {
      for (const item of intervention.value?.items) {
        if (typeof item.item === 'object' && item.item && 'id' in item.item && item.quantity) {
          if (!item.item.has_managed_stocks) {
            continue
          }
          itemStocks[item.item.id] ??= item.item.in_stock
          itemStocks[item.item.id] += item.quantity
        }
      }
    }

    for (const [id, quantity] of Object.entries(itemStocks)) {
      await $directus.request(
        updateItem('item', id, {
          in_stock: Math.max(0, quantity),
        }),
      )
    }
    for (const [id, quantity] of Object.entries(partStocks)) {
      await $directus.request(
        updateItem('spare_part', id, {
          in_stock: Math.max(0, quantity),
        }),
      )
    }

    if (userCredit?.amount && intervention.value?.customer?.id) {
      await $directus.request(
        updateItem('customer', intervention.value.customer?.id, {
          credit: addPrices(intervention.value.customer?.credit ?? 0, userCredit.amount),
        }),
      )
    }

    refundModal.success()
    toaster.push({
      title: 'Remboursement effectué',
      color: 'success',
      message: 'La facture a été annulée avec succès',
      closable: true,
      actions: [
        {
          label: 'Imprimer',
          icon: 'ph:printer',
          props: {
            size: 'sm',
            onClick: () => {
              if (!creditNote?.id || !intervention.value?.id)
                return
              const { printUrl } = usePrint({
                sandbox: false,
                delay: 1000,
              })
              toaster.clear()
              printUrl(`/invoice/${intervention.value.id}/${creditNote.id}/print`)
            },
          },
        },
        {
          label: 'Ouvrir l\'avoir',
          icon: 'ph:arrow-square-in',
          props: {
            size: 'sm',
            color: 'primary',
            variant: 'pastel',
            to: `/invoice/${intervention.value.id}/${creditNote.id}/print`,
            target: '_blank',
            onClick: () => {
              toaster.clear()
            },
          },
        },
      ],
    }, {
      dismissible: false,
      duration: 5000,
    })
  }
  catch (error) {
    toaster.push({
      title: 'Erreur',
      color: 'danger',
      message: 'Une erreur est survenue lors de l\'annulation de la facture',
      icon: 'ph:x-circle',
      closable: true,
    })
    console.error(error)
    $sentry.captureException(error)
  }
  finally {
    pending.value = false
  }
}

function isItemStockManaged(itemId?: string | null) {
  if (!itemId)
    return false

  const item = intervention.value?.items?.find(item => item?.item?.id === itemId)
  return item?.item?.has_managed_stocks as boolean
}
function itemStock(itemId?: string | null) {
  if (!itemId)
    return 0

  const item = intervention.value?.items?.find(item => item?.item?.id === itemId)
  return item?.item?.in_stock as number ?? 0
}
function isSparePartStockManaged(sparePartId?: string | null) {
  if (!sparePartId)
    return false

  const planned = intervention.value?.planned_services?.find(
    planned => planned.require_spare_part?.some(sparePart => sparePart.spare_part?.id === sparePartId),
  )
  const sparePart = planned?.require_spare_part?.find(sparePart => sparePart.spare_part?.id === sparePartId)
  return sparePart?.spare_part?.has_managed_stocks as boolean
}
function sparePartStock(sparePartId?: string | null) {
  if (!sparePartId)
    return 0

  const planned = intervention.value?.planned_services?.find(
    planned => planned.require_spare_part?.some(sparePart => sparePart.spare_part?.id === sparePartId),
  )
  const sparePart = planned?.require_spare_part?.find(sparePart => sparePart.spare_part?.id === sparePartId)
  return (sparePart?.spare_part?.in_stock as number) ?? 0
}

const onSubmit = handleSubmit(
  async (values) => {
    if (!invoice.value || !intervention.value) {
      return
    }
    if (values.type === 'cancel') {
      await cancelInvoice(values.comment)
    }
    else {
      if (amount.value === 0) {
        toaster.push({
          title: 'Erreur',
          color: 'danger',
          message: 'Le montant remboursé ne peut pas être nul',
          icon: 'ph:x-circle',
          closable: true,
        })
        return
      }
      if (amount.value !== transactionsAmount.value) {
        toaster.push({
          title: 'Erreur',
          color: 'danger',
          message: 'Les transactions doivent correspondre au montant remboursé',
          icon: 'ph:x-circle',
          closable: true,
        })
        return
      }

      if (pending.value) {
        return
      }

      pending.value = true

      try {
        const refundTotal = (invoice.value.details?.refund_total ?? 0) + amount.value
        if (refundTotal > invoice.value.total) {
          toaster.push({
            title: 'Erreur',
            color: 'danger',
            message: 'Le montant remboursé ne peut pas être supérieur au montant total de la facture',
            icon: 'ph:x-circle',
            closable: true,
          })
          return
        }

        const invoiceStatus = refundTotal === invoice.value.total ? 'refunded' : 'partially_refunded'
        const userCredit = values.transactions?.find(transaction => transaction.type === 'user_credit')

        if (userCredit?.amount && !intervention.value.customer?.id) {
          toaster.push({
            title: 'Erreur',
            color: 'danger',
            message: 'Le client doit être renseigné pour créditer son compte',
            icon: 'ph:x-circle',
            closable: true,
          })
          return
        }

        const partialDetails = {
          customer: invoice.value.details.customer,
          device_model: invoice.value.details.device_model,
          services: values.services
            ?.filter(service => service.checked)
            .map(service => ({
              id: service.id,
              name: service.name,
              total: service.amount * -1,
              total_excl_discount: service.amount * -1,
              subtotal: roundPrice((service.amount - service.amount / (1 + service.tax_ratio) * service.tax_ratio) * -1),
              subtotal_excl_discount: roundPrice((service.amount - service.amount / (1 + service.tax_ratio) * service.tax_ratio) * -1),
              discount: 0,
              quantity: 1,
              tax_ratio: service.tax_ratio,
              tax_total: roundPrice((service.amount / (1 + service.tax_ratio) * service.tax_ratio) * -1),
              cost_total: 0,
              discount_id: null,
              unitary_cost: 0,
              discount_type: null,
              unitary_price_excl_tax: roundPrice((service.amount - service.amount / (1 + service.tax_ratio) * service.tax_ratio) * -1),
              unitary_price: service.amount * -1,
              discount_label: null,
              discount_total: 0,
              included_spare_parts: service.parts.map(sparePart => ({
                id: sparePart.id,
                name: sparePart.name,
                quantity: sparePart.quantity,
                reference: null,
              })),
            })) ?? [],
          items: values.items
            ?.filter(item => item.checked)
            .map(item => ({
              id: item.id,
              name: item.name,
              total: item.amount * -1,
              total_excl_discount: item.amount * -1,
              discount: 0,
              quantity: item.quantity,
              subtotal: roundPrice((item.amount - item.amount / (1 + item.tax_ratio) * item.tax_ratio) * -1),
              subtotal_excl_discount: roundPrice((item.amount - item.amount / (1 + item.tax_ratio) * item.tax_ratio) * -1),
              reference: null,
              tax_ratio: item.tax_ratio,
              tax_total: item.is_second_hand ? 0 : roundPrice((item.amount / (1 + item.tax_ratio) * item.tax_ratio) * -1),
              cost_total: 0,
              gain_total: 0,
              discount_id: null,
              unitary_cost: 0,
              discount_type: null,
              unitary_price: roundPrice((item.amount / item.quantity) * -1),
              unitary_price_excl_tax: roundPrice((item.amount - item.amount / (1 + item.tax_ratio) * item.tax_ratio) / item.quantity * -1),
              discount_label: null,
              discount_total: 0,
              is_second_hand: item.is_second_hand,
              tax_margin_total: 0,
            })) ?? [],
        }

        const partialInvoiceData = {
          comment: values.comment,
          details: partialDetails,
          type: 'credit_note',
          date_payed: new Date().toISOString(),
          intervention: invoice.value.intervention,
          customer: invoice.value.customer,
          duration: invoice.value.duration,
          item_quantity: partialDetails.items.reduce((acc, item) => acc + item.quantity, 0),
          item_total: addPrices(...partialDetails.items.map(item => item.total)),
          service_total: addPrices(...partialDetails.services.map(service => service.total)),
          total: addPrices(...partialDetails.items.map(item => item.total), ...partialDetails.services.map(service => service.total)),
          item_subtotal: addPrices(...partialDetails.items.map(item => item.subtotal)),
          service_subtotal: addPrices(...partialDetails.services.map(service => service.total)),
          subtotal: addPrices(...partialDetails.items.map(item => item.subtotal), ...partialDetails.services.map(service => service.total)),
          item_tax_total: addPrices(...partialDetails.items.map(item => item.tax_total)),
          service_tax_total: addPrices(...partialDetails.services.map(service => service.tax_total)),
          item_tax_margin_total: 0,
          tax_total: addPrices(...partialDetails.items.map(item => item.tax_total), ...partialDetails.services.map(service => service.tax_total)),
          cost_total: 0,
          discount_total: 0,
          gain_total: 0,
          item_cost_total: 0,
          item_discount_total: 0,
          item_gain_total: 0,
          service_cost_total: 0,
          service_discount_total: 0,
          service_gain_total: 0,
          transactions: values.transactions.map(transaction => ({
            type: transaction.type,
            amount: transaction.amount * -1,
            payment_method: transaction.payment_method,
            customer: transaction.customer,
            intervention: transaction.intervention,
          })),
        }

        const creditNote = await $directus.request(
          createItem('invoice', partialInvoiceData),
        )

        await $directus.request(
          createItem('invoice_references', {
            invoice_parent: invoice.value.id,
            invoice_child: creditNote.id,
          }),
        )

        await $directus.request(
          updateItem('invoice', invoice.value.id, {
            status: invoiceStatus,
            details: {
              ...(invoice.value.details ?? {}),
              refund_total: refundTotal,
              services: invoice.value.details.services.map((service) => {
                const relatedService = values.services.find(s => s.id === service.id)
                if (!relatedService) {
                  return service
                }

                return {
                  ...service,
                  refund_total: (service.refund_total ?? 0) + relatedService.amount,
                  included_spare_parts: service.included_spare_parts.map((sparePart) => {
                    const relatedPart = relatedService.parts.find(p => p.id === sparePart.id)
                    if (!relatedPart) {
                      return sparePart
                    }
                    return {
                      ...sparePart,
                      refund_quantity: (sparePart.refund_quantity ?? 0) + relatedPart.quantity,
                      refund_restocked: (sparePart.refund_restocked ?? 0) + relatedPart.restock,
                    }
                  }),
                }
              }),
              items: invoice.value.details.items.map((item) => {
                const relatedItem = values.items.find(i => i.id === item.id)
                if (!relatedItem) {
                  return item
                }
                return {
                  ...item,
                  refund_total: (item.refund_total ?? 0) + relatedItem.amount,
                  refund_quantity: (item.refund_quantity ?? 0) + relatedItem.quantity,
                  refund_restocked: (item.refund_restocked ?? 0) + relatedItem.restock,
                }
              }),
            },
          }),
        )

        if (invoiceStatus === 'refunded') {
          await $directus.request(
            updateItem('intervention', intervention.value.id, {
              status: values.status,
              date_payed: null,
              planned_services: intervention.value.planned_services?.map(planned => ({
                id: planned.id,
                status: values.status,
                custom_status: values.status,
              })),
              items: intervention.value.items?.map(item => ({
                id: item.id,
                status: values.status,
              })),
            }),
          )
        }

        // stock management
        const partStocks = {} as Record<string, number>
        const itemStocks = {} as Record<string, number>
        for (const planned of values.services) {
          for (const part of planned.parts) {
            if (isSparePartStockManaged(part.id) && part.restock) {
              partStocks[part.id] ??= sparePartStock(part.id)
              partStocks[part.id] += part.restock
            }
          }
        }
        for (const item of values.items) {
          if (isItemStockManaged(item.id) && item.restock) {
            itemStocks[item.id] ??= itemStock(item.id)
            itemStocks[item.id] += item.restock
          }
        }

        for (const [id, quantity] of Object.entries(itemStocks)) {
          await $directus.request(
            updateItem('item', id, {
              in_stock: Math.max(0, quantity),
            }),
          )
        }
        for (const [id, quantity] of Object.entries(partStocks)) {
          await $directus.request(
            updateItem('spare_part', id, {
              in_stock: Math.max(0, quantity),
            }),
          )
        }

        if (userCredit?.amount && intervention.value?.customer?.id) {
          await $directus.request(
            updateItem('customer', intervention.value.customer?.id, {
              credit: addPrices(intervention.value.customer?.credit ?? 0, userCredit.amount),
            }),
          )
        }

        refundModal.success()
        toaster.push({
          title: 'Remboursement effectué',
          color: 'success',
          message: 'La facture a été annulée avec succès',
          closable: true,
          actions: [
            {
              label: 'Ouvrir l\'avoir',
              icon: 'ph:arrow-square-in',
              props: {
                size: 'sm',
                to: `/invoice/${intervention.value.id}/${creditNote.id}/print`,
                target: '_blank',
                onClick: () => {
                  toaster.clear()
                },
              },
            },
            {
              label: 'Imprimer',
              icon: 'ph:printer',
              props: {
                size: 'sm',
                color: 'primary',
                variant: 'pastel',
                onClick: () => {
                  if (!creditNote?.id || !intervention.value?.id)
                    return
                  const { printUrl } = usePrint({
                    sandbox: false,
                    delay: 1000,
                  })
                  toaster.clear()
                  printUrl(`/invoice/${intervention.value.id}/${creditNote.id}/print`)
                },
              },
            },
          ],
        }, {
          dismissible: false,
          duration: 5000,
        })
      }
      catch (error) {
        toaster.push({
          title: 'Erreur',
          color: 'danger',
          message: 'Une erreur est survenue lors de la création de l\'avoir',
          icon: 'ph:x-circle',
          closable: true,
        })
        console.error(error)
        $sentry.captureException(error)
      }
      finally {
        pending.value = false
      }
    }
  },
  (error) => {
    if (import.meta.dev) {
      console.log('Validation failed', error)
    }
  },
)
</script>

<template>
  <BaseModal :size="!invoice || invoice.status === 'refunded' ? 'md' : '2xl'" open>
    <template #header>
      
      <div class="flex w-full items-center justify-between border-b border-muted-200 p-2 dark:border-muted-700 lg:p-3">
        <div class="flex items-center gap-4">
          <h3
            class="text-lg font-medium leading-6 text-muted-900 dark:text-white"
          >
            Remboursement
          </h3>
          <BaseTag
            color="muted"
          >
            {{ formatInvoiceType(invoice.type) }} {{ invoice.id }}
          </BaseTag>
        </div>
        <BaseTooltip
          content="Annuler"
          placement="left"
          :offset="4"
        >
          <BaseButtonClose
            size="sm"
            @click="refundModal.cancel()"
          />
        </BaseTooltip>
      </div>
    </template>
    <div class="p-4 lg:p-6 max-h-[70dvh] overflow-scroll nui-slimscroll">
      <div v-if="interventionStatus === 'pending' || interventionStatus === 'idle'">
        Chargement...
      </div>
      <div v-if="interventionStatus === 'error'">
        Une erreur est survenue lors du chargement de l'intervention
      </div>
      <div v-else-if="interventionStatus === 'success' && intervention">
        <div v-if="!invoice">
          <BaseMessage color="warning">
            <Icon name="ph:info" class="me-1 size-4" />
            <span>Cette facture n'existe pas</span>
          </BaseMessage>
          <div class="text-end mt-8">
            <BaseButton
              color="muted"
              :disabled="pending"
              @click="() => refundModal.cancel()"
            >
              Fermer
            </BaseButton>
          </div>
        </div>
        <div v-else-if="invoice.status === 'refunded'">
          <BaseMessage color="warning">
            <Icon name="ph:info" class="me-1 size-4" />
            <span>Cette facture a déjà été remboursée</span>
          </BaseMessage>
          <div class="text-end mt-8">
            <BaseButton
              color="muted"
              :disabled="pending"
              @click="() => refundModal.cancel()"
            >
              Fermer
            </BaseButton>
          </div>
        </div>
        <form
          v-else
          tabindex="0"
          novalidate
          autocomplete="off"
          @submit.prevent="onSubmit"
        >
          <div class="flex gap-4 min-h-32 flex-col md:flex-row">
            <div class="md:w-1/2 space-y-4">
              <div class="flex justify-between items-center">
                <Field
                  v-slot="{ field, handleChange }"
                  name="type"
                >
                  <BaseButtonGroup>
                    <BaseButton
                      size="sm"
                      :disabled="invoice.status === 'partially_refunded'"
                      :color="field.value === 'cancel' ? 'primary' : 'default'"
                      @click="() => {
                        initValues()
                        handleChange('cancel')
                      }"
                    >
                      Annulation
                    </BaseButton>
                    <BaseButton
                      size="sm"
                      :color="field.value === 'partial' ? 'primary' : 'default'"
                      @click="() => {
                        handleChange('partial')
                      }"
                    >
                      Remboursement partiel
                    </BaseButton>
                  </BaseButtonGroup>
                </Field>
              </div>

              
              <BaseCard v-for="(service, idx) in values.services" :key="service.id" class="p-2 ps-4">
                <div class="flex justify-between items-center">
                  <BaseCheckbox
                    :model-value="service.checked"
                    :label="service?.name"
                    :disabled="values.type === 'cancel' || pending"
                    @update:model-value="(value) => {
                      if (value) {
                        setFieldValue(`services.${idx}.amount`, service.amount_max)
                      }
                      else {
                        setFieldValue(`services.${idx}.amount`, 0)
                      }
                      setFieldValue(`services.${idx}.checked`, value)
                    }"
                  />

                  <div class="w-1/3">
                    <BaseInputNumber
                      :model-value="service.amount"
                      :model-modifiers="{ lazy: true, raw: true }"
                      inputmode="numeric"
                      size="sm"
                      class="text-end"
                      :min="0"
                      :max="service.amount_max"
                      :disabled="values.type === 'cancel' || !service.checked || pending"
                      @update:model-value="(value) => setFieldValue(`services.${idx}.amount`, parseFloatInput(value, 0))"
                    />
                  </div>
                </div>
                <div v-if="service.parts?.length" class="mt-1 pt-1 space-y-2 border-t border-muted-200">
                  <div v-for="(sparePart, partIdx) in service.parts" :key="sparePart.id" class="flex justify-between items-center h-8">
                    
                    <span class="text-sm text-muted-400">{{ sparePart?.name }}</span>
                    
                    <div class="flex items-center justify-center">
                      <div
                        v-if="!isSparePartStockManaged(sparePart?.id)"
                        class="whitespace-nowrap text-center font-sans text-xs font-medium text-muted-500 pe-2"
                      >
                        Stock non géré
                      </div>
                      <div
                        v-else
                        class="mx-auto flex max-w-[150px] items-center justify-between font-sans text-xs font-medium text-primary-500"
                      >
                        <div class="-m-2 p-2" @click.stop>
                          <BaseButtonIcon
                            size="sm"
                            rounded="full"
                            class="scale-[0.8]"
                            aria-label="Décrémenter le stock"
                            :disabled="values.type === 'cancel' || !service.checked || pending"
                            @click="() => setFieldValue(`services.${idx}.parts.${partIdx}.restock`, Math.max(sparePart.restock - 1, 0))"
                          >
                            <Icon name="lucide:minus" class="size-4" />
                          </BaseButtonIcon>
                        </div>
                        <div class="relative">
                          <div
                            class="absolute inset-0 flex items-center justify-center text-muted-500 dark:text-muted-300"
                          >
                            {{ sparePart.restock }}
                          </div>
                          <BaseProgressCircle
                            :size="33"
                            :thickness="3"
                            color="success"
                            :value="sparePart.restock"
                            :max="sparePart.restock_max"
                          />
                        </div>
                        <div class="-m-2 p-2" @click.stop>
                          <BaseButtonIcon
                            size="sm"
                            rounded="full"
                            class="scale-[0.8]"
                            aria-label="Incrémenter le stock"
                            :disabled="values.type === 'cancel' || !service.checked || pending"
                            @click="() => setFieldValue(`services.${idx}.parts.${partIdx}.restock`, Math.min(sparePart.restock + 1, sparePart.restock_max))"
                          >
                            <Icon name="lucide:plus" class="size-4" />
                          </BaseButtonIcon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BaseCard>

              
              <BaseCard v-for="(item, idx) in values.items" :key="item.id" class="p-2 ps-4">
                <div class="flex justify-between items-center">
                  <BaseCheckbox
                    :model-value="item.checked"
                    :label="item?.name"
                    :disabled="values.type === 'cancel' || pending"
                    @update:model-value="(value) => {
                      if (value) {
                        setFieldValue(`items.${idx}.amount`, item.amount_max)
                        setFieldValue(`items.${idx}.restock`, item.restock_max)
                      }
                      else {
                        setFieldValue(`items.${idx}.amount`, 0)
                        setFieldValue(`items.${idx}.restock`, 0)
                      }
                      setFieldValue(`items.${idx}.checked`, value)
                    }"
                  />

                  <div class="w-1/3">
                    <BaseInputNumber
                      :model-value="item.amount"
                      :model-modifiers="{ lazy: true, raw: true }"
                      inputmode="numeric"
                      class="text-end"
                      size="sm"
                      :min="0"
                      :max="item.amount_max"
                      :disabled="values.type === 'cancel' || !item.checked || pending"
                      @update:model-value="(value) => setFieldValue(`items.${idx}.amount`, parseFloatInput(value, 0))"
                    />
                  </div>
                </div>
                <div class="mt-1 pt-1 space-y-2 border-t border-muted-200">
                  <div class="flex justify-between items-center h-8">
                    <span class="text-sm text-muted-400">Stock</span>
                    <div class="flex items-center justify-center">
                      <div
                        v-if="!isItemStockManaged(item?.id)"
                        class="whitespace-nowrap text-center font-sans text-xs font-medium text-muted-500 pe-2"
                      >
                        Stock non géré
                      </div>
                      <div
                        v-else
                        class="mx-auto flex max-w-[150px] items-center justify-between font-sans text-xs font-medium text-primary-500"
                      >
                        <div class="-m-2 p-2" @click.stop>
                          <BaseButtonIcon
                            size="sm"
                            rounded="full"
                            class="scale-[0.8]"
                            aria-label="Décrémenter le stock"
                            :disabled="values.type === 'cancel' || !item.checked || pending"
                            @click="() => setFieldValue(`items.${idx}.restock`, Math.max(item.restock - 1, 0))"
                          >
                            <Icon name="lucide:minus" class="size-4" />
                          </BaseButtonIcon>
                        </div>
                        <div class="relative">
                          <div
                            class="absolute inset-0 flex items-center justify-center text-muted-500 dark:text-muted-300"
                          >
                            {{ item.restock }}
                          </div>
                          <BaseProgressCircle
                            :size="33"
                            :thickness="3"
                            color="success"
                            :value="item.restock"
                            :max="item.restock_max"
                          />
                        </div>
                        <div class="-m-2 p-2" @click.stop>
                          <BaseButtonIcon
                            size="sm"
                            rounded="full"
                            class="scale-[0.8]"
                            aria-label="Incrémenter le stock"
                            :disabled="values.type === 'cancel' || !item.checked || pending"
                            @click="() => setFieldValue(`items.${idx}.restock`, Math.min(item.restock + 1, item.restock_max))"
                          >
                            <Icon name="lucide:plus" class="size-4" />
                          </BaseButtonIcon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BaseCard>
            </div>
            <div class="md:w-1/2 md:ps-4 md:border-s md:border-s-muted-200">
              <div class="mb-4">
                <BaseTag
                  rounded="full"
                  color="muted-contrast"
                  class="m-0 -ml-1 inline-flex h-5 shrink-0 items-center py-0 font-mono !text-sm font-semibold"
                >
                  {{ intervention?.id?.substring(0, 4) }}
                </BaseTag>
              </div>

              <div v-for="(transaction, idx) in values.transactions" :key="idx" class="mb-2">
                <BaseButtonGroup>
                  <BaseButton
                    v-if="transaction.type === 'bank'"
                    disabled
                    color="muted"
                    class="!px-2"
                  >
                    <Icon
                      v-if="paymentMethods.getById(transaction.payment_method)?.icon"
                      :name="paymentMethods.getById(transaction.payment_method)!.icon"
                      class="size-4 shrink-0"
                    />
                    <span class="whitespace-nowrap">{{
                      paymentMethods.getById(transaction.payment_method)?.label
                    }}</span>
                  </BaseButton>
                  <BaseButton
                    v-else
                    disabled
                    color="muted"
                    class="!px-2"
                  >
                    <Icon
                      name="ph:user"
                      class="size-4 shrink-0"
                    />
                    <span class="whitespace-nowrap">Solde client</span>
                  </BaseButton>
                  <BaseInputNumber
                    :model-value="transaction.amount"
                    :model-modifiers="{ lazy: true, raw: true }"
                    inputmode="numeric"
                    class="text-end"
                    :min="0"
                    :disabled="values.type === 'cancel' || pending"
                    @update:model-value="(value) => {
                      setFieldValue(`transactions.${idx}.amount`, parseFloatInput(value, 0))
                    }"
                  />
                  <BaseButton
                    :disabled="values.type === 'cancel' || pending"
                    class="!px-3"
                    @click="() => {
                      // values.transactions?.splice(idx, 1)
                      setFieldValue(`transactions`, values.transactions?.filter((_, i) => i !== idx) ?? [])
                    }"
                  >
                    <Icon name="ph:x" class="size-4" />
                  </BaseButton>
                </BaseButtonGroup>
              </div>

              <div>
                <BaseDropdown
                  :disabled="values.type === 'cancel' || transactionsAmount === amountMax || pending"
                  :classes="{ wrapper: 'dropdown-full' }"
                  fixed
                  placement="bottom-start"
                >
                  <template #button>
                    <BaseButton
                      :color="!values.transactions?.length ? 'primary' : 'default'"
                      :style="{
                        width: '100%',
                      }"
                      :class="!values.transactions?.length ? '!dark:text-primary-800 ' : ''"
                    >
                      <span>Ajouter un moyen de paiement</span>
                      <Icon
                        name="ph:caret-down"
                        class="size-4"
                      />
                    </BaseButton>
                  </template>

                  <BaseDropdownItem
                    v-for="method in availablePaymentMethods"
                    :key="method.id"
                    :title="method.label!"
                    class="outline outline-offset-2 outline-transparent"
                    @click="() => {
                      setFieldValue(`transactions`, [
                        ...values.transactions,
                        {
                          type: 'bank',
                          payment_method: method.id,
                          intervention: invoice.intervention,
                          customer: invoice.customer,
                          amount: amount - transactionsAmount,
                        },
                      ])
                    }"
                  >
                    <template #start>
                      <Icon
                        :name="method.icon!"
                        class="me-2 block size-5"
                      />
                    </template>
                  </BaseDropdownItem>

                  <BaseDropdownItem
                    v-if="invoice.customer && values.transactions?.every(transaction => transaction.type !== 'user_credit')"
                    title="Solde client"
                    class="outline outline-offset-2 outline-transparent"
                    @click="() => {
                      setFieldValue(`transactions`, [
                        ...values.transactions,
                        {
                          type: 'user_credit',
                          intervention: invoice.intervention,
                          customer: invoice.customer,
                          amount: amount - transactionsAmount,
                        },
                      ])
                    }"
                  >
                    <template #start>
                      <Icon
                        name="ph:user"
                        class="me-2 block size-5"
                      />
                    </template>
                  </BaseDropdownItem>
                </BaseDropdown>
              </div>

              <div class="flex justify-between mt-4">
                <span>Sous-total TTC</span>
                <span class="">
                  {{ formatPrice(invoice.total) }}
                </span>
              </div>

              <div v-if="invoice.details?.refund_total" class="flex justify-between">
                <span>Avoir émis</span>
                <span class="">
                  {{ formatPrice(invoice.details?.refund_total) }}
                </span>
              </div>

              <div class="flex justify-between" :class="[amount === 0 && 'text-danger-500']">
                <span>Total Remboursé</span>
                <span class="">
                  {{ formatPrice(amount) }}
                </span>
              </div>

              <div class="mt-4">
                <Field
                  v-slot="{ field, errorMessage, handleChange, handleBlur }"
                  name="comment"
                >
                  <BaseTextarea
                    :model-value="field.value"
                    :error="errorMessage"
                    label="Note"
                    placeholder="Avoir suite à ..."
                    rows="1"
                    autogrow
                    :classes="{
                      textarea: 'nui-slimscroll',
                    }"
                    @update:model-value="
                      (value: any) => {
                        handleChange(value)
                      }
                    "
                    @blur="handleBlur"
                  />
                </Field>
              </div>
            </div>
          </div>
          <div class="flex justify-end w-full items-center mt-8 gap-4">
            <div class="group">
              <span
                class="flex justify-end items-center gap-1 font-sans text-xs font-semibold uppercase leading-none tracking-wide text-muted-400"
              >
                <span>Restant dû</span>
              </span>
              <span
                class="flex justify-end items-start gap-1 text-muted-900 dark:text-muted-200"
              >
                <span class="flex flex-col items-end gap-0">
                  <span class="text-2xl font-semibold" :class="[amount - transactionsAmount !== 0 && 'text-danger-500']">
                    {{ formatPrice(amount - transactionsAmount) }}
                  </span>
                </span>
              </span>
            </div>

            <BaseButton
              type="submit"
              color="success"
              size="lg"
              :disabled="pending"
            >
              Créer un avoir
            </BaseButton>
          </div>
        </form>
      </div>
    </div>
  </BaseModal>
</template>

<style scoped>
.dropdown-full {
  width: 100%;
}
.dropdown-full:deep(.nui-menu) {
  width: 100%;
}
</style>
