import type { CustomDirectusTypes, DirectusTypes, Intervention } from '@laast-io/types'
import { type Query, withOptions } from '@directus/sdk'
import type { DirectusLaastClient } from '~/types/api'
import type { RefOrGetter } from '~/types/utils'
import { hydrateIntervention } from '~/utils/hydrate/intervention'

interface Input {
  id: RefOrGetter<string | undefined>
}

export type FetchInterventionRefund = Awaited<ReturnType<typeof fetchInterventionRefund>>

export function useAsyncModalInterventionRefund({
  id,
}: Input) {
  const $directus = useDirectusSdk()
  let controller: AbortController | undefined
  onScopeDispose(() => {
    controller?.abort()
  })

  return useAsyncData(
    `modals-intervention-refund:${toValue(id) ?? ''}`,
    async (): Promise<FetchInterventionRefund | null> => {
      controller?.abort()
      controller = undefined

      const _id = toValue(id)

      if (!_id) {
        return null
      }

      controller = new AbortController()

      try {
        return await fetchInterventionRefund($directus, { id: _id }, {
          signal: controller.signal,
        })
      }
      catch (error) {
        catchDirectusFetchError(error)
        return null
      }
      finally {
        controller = undefined
      }
    },
    {
      watch: [() => toValue(id)],
    },
  )
}

async function fetchInterventionRefund(
  $directus: DirectusLaastClient,
  params: {
    id: string
  },
  options: Partial<RequestInit>,
) {
  const query = {
    fields: [
      'id',
      'status',
      'date_scheduled',
      'date_estimated',
      'date_created',
      'date_updated',
      'date_started',
      'date_completed',
      'date_payed',
      'date_status_updated',
      'estimated_time',
      'comment',
      'comment_invoice',
      {
        customer: [
          'id',
          'first_name',
          'last_name',
          'company',
          'phone',
          'email',
          'credit',
          'messages_status',
          // @todo: unread count  via directus aliases
          'messages_agent_unread',
          {
            addresses: ['id', 'line_1', 'line_2', 'city', 'zip_code'],
          },
        ],
        customer_device: ['id', 'serial_number'],
        device_model: ['id', 'name', 'device_category'],
        items: [
          'id',
          'quantity',
          'discount',
          'discount_id',
          'discount_label',
          'discount_type',
          {
            item: [
              'id',
              'name',
              'reference',
              'provider',
              'in_stock',
              'has_managed_stocks',
              'is_second_hand',
              'unitary_cost',
              'unitary_price',
              'tax_ratio',
            ],
          },
        ],
        invoices: [
          '*',
          {
            transactions: ['*'],
          },
        ],
        transactions: ['*'],
        planned_services: [
          'id',
          'custom_status',
          'order',
          'discount',
          'discount_id',
          'discount_label',
          'discount_type',
          {
            service: ['*'],
            require_spare_part: [
              'id',
              'quantity',
              'stock_deducted',
              'price_modifier',
              {
                spare_part: [
                  'id',
                  'name',
                  'reference',
                  'provider',
                  'in_stock',
                  'has_managed_stocks',
                  'unitary_cost',
                ],
              },
            ],
          },
        ],
      },
    ],
  } as const satisfies Query<Omit<CustomDirectusTypes, DirectusTypes>, Intervention>

  ;(query as any).deep = {
    planned_services: {
      _sort: 'order',
    },
  }

  const response = await $directus
    .request(
      withOptions(
        readItem('intervention', params.id, query),
        options,
      ),
    )

  return hydrateIntervention(response)
}
