<script setup lang="ts">
const panels = reactive(usePanels())

const { $posthog } = useNuxtApp()
const route = useRoute()
const { isSidebarCollapsed, isSidebarOpenMobile } = createLayoutContext()
const payementModal = useInterventionPayementModal()
const refundModal = useInterventionRefundModal()
const { form } = useInterventionCartForm()
const quote = useInterventionQuote(form.values)

onKeyStroke('Escape', () => {
  if (panels.canPop) {
    panels.pop()
  }
})

const showCartButton = useShowCart()

async function createIntervention() {
  $posthog?.capture('app_show_cart_click')
  await panels.push('intervention-create', {})
}

let lastScrollY = 0
const threshold = 25 // half of the navbar height
const direction = ref<'up' | 'down'>('down')
const { y } = useWindowScroll()
watch(y, (value) => {
  if (lastScrollY < value) {
    direction.value = 'down'
  }
  else {
    direction.value = 'up'
  }

  lastScrollY = value
})
const isScrolled = computed(() => {
  return y.value > threshold
})

const disableMobileNavScroll = computed(() => {
  return route.path === '/' || route.path === '/agenda'
})
</script>

<template>
  <div class="bg-muted-100 dark:bg-muted-900">
    <div>
      <LayoutSidebar />

      
      <div
        class="h-[57px] w-full border-b border-muted-200 bg-white  transition-transform duration-150 dark:border-muted-700 dark:bg-muted-800 md:hidden md:transform-none"
        :class="{
          'fixed top-0 z-30': !disableMobileNavScroll,
          'shadow-md shadow-muted-300/30 dark:shadow-muted-900/20': isScrolled,
          '-translate-y-full': !disableMobileNavScroll && isScrolled && direction === 'down',
        }"
      >
        <div class="grid h-[57px] w-full grid-cols-6 items-center justify-between px-4">
          <div class="col-span-1">
            <LayoutHamburgerMobile />
            <LayoutHamburger />
          </div>

          <div class="col-span-4 flex w-full">
            <LayoutSidebarLogo
              width="68"
              height="30"
              class="mx-auto"
              @click="isSidebarOpenMobile = !isSidebarOpenMobile"
            />
          </div>

          <div class="col-span-1">
            
          </div>
        </div>
      </div>

      
      <main
        class="relative w-full  transition-all duration-150 md:pt-0 md:transition-none"
        :class="[
          disableMobileNavScroll ? '' : 'min-h-dvh pt-[57px]',
          isScrolled ? '' : '',
          showCartButton ? 'pb-12 md:pb-0' : '',
          isSidebarOpenMobile ? 'h-screen overflow-hidden' : '',
          isSidebarCollapsed
            ? 'ml-0 max-w-full md:ml-[64px] md:max-w-[calc(100%_-_64px)]'
            : 'ml-0 max-w-full overflow-hidden md:ml-[64px] md:h-screen md:max-w-[calc(100%_-_64px)] md:overflow-auto lg:ml-[256px] lg:max-w-[calc(100%_-_256px)]',
        ]"
      >
        <slot />
      </main>

      
      <div
        role="button"
        tabindex="0"
        class="fixed left-0 top-0 z-[200] size-full bg-muted-900 transition-opacity duration-200 dark:bg-muted-950"
        :class="
          panels.canPop
            ? 'opacity-80 pointer-events-auto'
            : 'opacity-0 pointer-events-none'
        "
        @click="() => panels.pop()"
      />

      
      <div
        role="button"
        tabindex="0"
        class="fixed left-0 top-0 z-40 size-full bg-muted-900 transition-opacity duration-200 dark:bg-muted-950"
        :class="
          isSidebarOpenMobile
            ? 'opacity-80 pointer-events-auto'
            : 'opacity-0 pointer-events-none'
        "
        @click="() => { isSidebarOpenMobile = false }"
      />
      
      <div
        role="button"
        tabindex="0"
        class="fixed left-0 top-0 z-40 hidden size-full bg-muted-900 transition-opacity duration-200 dark:bg-muted-950 md:block lg:hidden"
        :class="
          !isSidebarCollapsed
            ? 'opacity-80 pointer-events-auto'
            : 'opacity-0 pointer-events-none'
        "
        @click="() => { isSidebarCollapsed = true }"
      />

      <BaseButton
        v-if="showCartButton"
        class="fixed bottom-0 end-0 start-0 z-30 flex items-center gap-2 shadow-lg md:nui-button-rounded-full dark:!text-black md:bottom-20 md:end-8 md:start-auto"
        size="lg"
        color="primary"
        rounded="none"
        @click="() => createIntervention()"
      >
        <Icon name="ph:basket-duotone" class="size-5" />
        <span>{{ formatPrice(quote.priceTotal) }}</span>
      </BaseButton>

      <template v-for="(panel, idx) in panels.stack" :key="panel.id">
        <Suspense>
          <component
            :is="resolveComponent(panel.component)"
            v-show="idx === panels.stack.length - 1"
            :key="panel.id"
            :active="idx === panels.stack.length - 1"
            :position="panel.position"
            :size="panel.size"
            v-bind="panel.props"
          />
          <template #fallback>
            <PanelLoader
              :position="panel.position"
              :size="panel.size"
            />
          </template>
        </Suspense>
      </template>
    </div>

    <ModalInterventionPayment
      v-if="payementModal.currentId"
      :intervention-id="payementModal.currentId"
    />

    <ModalInterventionRefund
      v-if="refundModal.currentId"
      :intervention-id="refundModal.currentId"
      :invoice-id="refundModal.invoiceId"
    />

    <ClientOnly>
      <AppPwa />
    </ClientOnly>
  </div>
</template>

<style>
.elipsis::after {
  display: inline-block;
  animation: elipsis steps(1, end) 1.8s infinite;
  content: ' ';
  min-width: 10px;
}

@keyframes elipsis {
  0% {
    content: ' ';
  }
  25% {
    content: ' .';
  }
  50% {
    content: ' ..';
  }
  75% {
    content: ' ...';
  }
  100% {
    content: ' ';
  }
}
</style>
