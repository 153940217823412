<script setup lang="ts">
const { isSidebarCollapsed } = useLayoutContext()
const panels = usePanels()
const { $posthog } = useNuxtApp()

async function createCustomer() {
  $posthog?.capture('sidebar_quickaction', { quickaction: 'create_customer' })
  await panels.push('customer-create', {})
}
async function createService() {
  $posthog?.capture('sidebar_quickaction', { quickaction: 'create_service' })
  await panels.push('service-create', {})
}
async function createPart() {
  $posthog?.capture('sidebar_quickaction', { quickaction: 'create_spare_part' })
  await panels.push('spare-part-create', {})
}
async function createItem() {
  $posthog?.capture('sidebar_quickaction', { quickaction: 'create_item' })
  await panels.push('item-create', {})
}
async function createDeviceModel() {
  $posthog?.capture('sidebar_quickaction', { quickaction: 'create_device_model' })
  await panels.push('device-model-create', {})
}
async function createIntervention() {
  $posthog?.capture('sidebar_quickaction', { quickaction: 'create_intervention' })
  await panels.push('intervention-create', {})
}
</script>

<template>
  <div
    class="flex w-full"
    :class="{
      'justify-center': isSidebarCollapsed,
    }"
  >
    <BaseDropdown
      size="md"
      placement="bottom-start"
      :classes="{
        wrapper: isSidebarCollapsed ? '' : 'w-full',
        menuWrapper: isSidebarCollapsed ? '' : 'w-full',
      }"
    >
      <template #button>
        <BaseButton
          color="primary"
          class="w-full !px-4 font-medium dark:!text-black"
        >
          <Icon name="lucide:plus" class="size-4" />
          <span v-if="!isSidebarCollapsed">Créer</span>
        </BaseButton>
      </template>

      <BaseDropdownItem
        title="Vente"
        :classes="{
          title:
            'font-heading text-muted-800 text-sm font-normal leading-tight dark:text-white',
        }"
        @click="() => createIntervention()"
      >
        <template #start>
          <Icon name="ph:basket-duotone" class="size-4" />
        </template>
      </BaseDropdownItem>
      <BaseDropdownItem
        title="Client"
        :classes="{
          title:
            'font-heading text-muted-800 text-sm font-normal leading-tight dark:text-white',
        }"
        @click="() => createCustomer()"
      >
        <template #start>
          <Icon name="ph:user-duotone" class="size-4" />
        </template>
      </BaseDropdownItem>
      <BaseDropdownItem
        title="Service"
        :classes="{
          title:
            'font-heading text-muted-800 text-sm font-normal leading-tight dark:text-white',
        }"
        @click="() => createService()"
      >
        <template #start>
          <Icon name="ph:wrench-duotone" class="size-4" />
        </template>
      </BaseDropdownItem>
      <BaseDropdownItem
        title="Pièce détaché"
        :classes="{
          title:
            'font-heading text-muted-800 text-sm font-normal leading-tight dark:text-white',
        }"
        @click="() => createPart()"
      >
        <template #start>
          <Icon name="ph:cpu-duotone" class="size-4" />
        </template>
      </BaseDropdownItem>
      <BaseDropdownItem
        title="Produit"
        :classes="{
          title:
            'font-heading text-muted-800 text-sm font-normal leading-tight dark:text-white',
        }"
        @click="() => createItem()"
      >
        <template #start>
          <Icon name="ph:package-duotone" class="size-4" />
        </template>
      </BaseDropdownItem>
      <BaseDropdownItem
        title="Appareil"
        :classes="{
          title:
            'font-heading text-muted-800 text-sm font-normal leading-tight dark:text-white',
        }"
        @click="() => createDeviceModel()"
      >
        <template #start>
          <Icon name="ph:device-mobile-camera-duotone" class="size-4" />
        </template>
      </BaseDropdownItem>
    </BaseDropdown>
  </div>
</template>
