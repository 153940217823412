<script setup lang="ts">
const { isSidebarOpenMobile } = useLayoutContext()
</script>

<template>
  <button
    class="nui-focus relative size-10 scale-90 text-muted-400 focus:outline-none lg:hidden"
    @click="isSidebarOpenMobile = !isSidebarOpenMobile"
  >
    <span class="sr-only">Open mobile menu</span>
    <div
      class="absolute left-1/2 top-1/2 block w-5 -translate-x-1/2 -translate-y-1/2"
    >
      <span
        aria-hidden="true"
        class="absolute block h-0.5 w-5 bg-current transition duration-500 ease-in-out"
        :class="isSidebarOpenMobile ? 'rotate-45' : '-translate-y-1.5'"
      />
      <span
        aria-hidden="true"
        class="absolute block h-0.5 w-5 bg-current transition duration-500 ease-in-out"
        :class="isSidebarOpenMobile ? 'opacity-0' : ''"
      />
      <span
        aria-hidden="true"
        class="absolute block h-0.5 w-5 bg-current transition duration-500 ease-in-out"
        :class="isSidebarOpenMobile ? '-rotate-45' : 'translate-y-1.5'"
      />
    </div>
  </button>
</template>
