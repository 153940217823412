import type { InjectionKey } from 'vue'

type LayoutContext = ReturnType<typeof createLayoutContext>
const layoutContextInjectionKey = Symbol(
  'layout-context',
) as InjectionKey<LayoutContext>

export function createLayoutContext() {
  const route = useRoute()
  const { xl, lg, md } = useTailwindBreakpoints()
  const isSidebarCollapsed = useLocalStorage('sidebar-collapsed', () => false)
  const isSidebarOpenMobile = ref(false)

  const layoutContext = {
    isSidebarOpenMobile,
    isSidebarCollapsed,
  }
  provide(layoutContextInjectionKey, layoutContext)
  watch(() => route.fullPath, () => {
    if (!lg.value) {
      isSidebarCollapsed.value = true
    }
    isSidebarOpenMobile.value = false
  })
  watch(md, (value) => {
    if (!value) {
      isSidebarCollapsed.value = false
      isSidebarOpenMobile.value = false
    }
    else {
      isSidebarCollapsed.value = true
      isSidebarOpenMobile.value = false
    }
  }, { immediate: true })
  watch(xl, (value) => {
    if (!value) {
      isSidebarCollapsed.value = true
      isSidebarOpenMobile.value = false
    }
    else {
      isSidebarCollapsed.value = false
      isSidebarOpenMobile.value = false
    }
  }, { immediate: true })
  watch(isSidebarOpenMobile, (value) => {
    if (value) {
      isSidebarCollapsed.value = false
    }
  })

  return layoutContext
}

export function useLayoutContext() {
  const layoutContext = inject(layoutContextInjectionKey)
  if (!layoutContext)
    throw new Error('LayoutContext not found')

  return layoutContext
}
