<script setup lang="ts">
const { $posthog, $pwa } = useNuxtApp()

const loadingAction = ref(false)
const loadingDiscard = ref(false)

let timer: ReturnType<typeof setTimeout> | undefined
function doWithLoading(
  loading: Ref<boolean>,
  cb: () => void,
  timeout = 100,
) {
  if (loadingAction.value || loadingDiscard.value)
    return
  loading.value = true
  if (timer)
    clearTimeout(timer)

  nextTick(() => {
    timer = setTimeout(() => {
      timer = undefined
      cb()
      // loading.value = false
    }, timeout)
  })
}

function installPwa() {
  $posthog?.capture('pwa_install_click')

  doWithLoading(loadingAction, () => {
    $pwa?.install()
  }, 500)
}
function installDiscard() {
  $posthog?.capture('pwa_install_discard')

  doWithLoading(loadingDiscard, () => {
    $pwa?.cancelInstall()
  })
}

function updatePwa() {
  $posthog?.capture('pwa_update_click')

  doWithLoading(loadingAction, () => {
    $pwa?.updateServiceWorker()
  }, 500)
}
function updateDiscard() {
  $posthog?.capture('pwa_update_discard')

  doWithLoading(loadingDiscard, () => {
    $pwa?.cancelPrompt()
  })
}
</script>

<template>
  <div>
    <ToastDefault
      v-if="$pwa?.showInstallPrompt && !$pwa?.needRefresh"
      role="alert"
      class="pwa-toast"
    >
      <div class="text-md max-w-xs pb-3">
        Installer l'application sur votre appareil.
      </div>
      <div class="flex gap-2">
        <BaseButtonAction
          color="primary"
          class="dark:!text-black"
          :loading="loadingAction"
          @click="installPwa()"
        >
          Installer
        </BaseButtonAction>
        <BaseButtonAction
          color="muted"
          :loading="loadingDiscard"
          @click="installDiscard()"
        >
          Fermer
        </BaseButtonAction>
      </div>
    </ToastDefault>
    <ToastDefault
      v-else-if="$pwa?.needRefresh"
      role="alert"
      class="pwa-toast"
    >
      <div class="text-md max-w-xs pb-3">
        <span>
          Une mise à jour est disponible. Veuillez recharger l'application.
        </span>
      </div>
      <div class="flex gap-2">
        <BaseButtonAction
          v-if="$pwa?.needRefresh"
          color="primary"
          class="dark:!text-black"
          :loading="loadingAction"
          @click="updatePwa()"
        >
          Recharger
        </BaseButtonAction>
        <BaseButtonAction
          color="muted"
          :loading="loadingDiscard"
          @click="updateDiscard()"
        >
          Fermer
        </BaseButtonAction>
      </div>
    </ToastDefault>
  </div>
</template>

<style>
.pwa-toast {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 1000;
}
</style>
