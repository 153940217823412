<script setup lang="ts">
import type { PaymentMethod } from '@laast-io/types'
import { withQuery } from 'ufo'
import { useAsyncModalInterventionPayment } from '~/composables/data/modals/intervention-payment'
import { validatePayment } from '~/services/intervention'

const props = defineProps<{
  interventionId: string
}>()

const { $sentry } = useNuxtApp()
const $directus = useDirectusSdk()
const route = useRoute()
const payementModal = useInterventionPayementModal()
const deviceCategory = useDeviceCategory()
const paymentMethods = usePaymentMethods()
const toaster = useToaster()
const panels = usePanels()

const pending = ref(false)

const generateInvoice = ref(false)

const transactions = ref<{
  type: 'user_credit' | 'bank'
  amount: number
  payment_method: PaymentMethod | null
}[]>([])

const {
  data: intervention,
  status: interventionStatus,
} = await useAsyncModalInterventionPayment({
  id: () => props.interventionId,
})

const validTransactions = computed<{
  type: 'user_credit' | 'bank'
  amount: number
  payment_method: PaymentMethod | null
}[]>(() => {
  return transactions.value.filter(transaction => transaction.amount > 0 && (transaction.type === 'user_credit' || transaction.payment_method)) as any
})

const availablePaymentMethods = computed(() => {
  return paymentMethods.items.filter(method => transactions.value.every(transaction => transaction.payment_method?.id !== method.id))
})

const invoiceDetails = computed(() => {
  return computeInvoiceData(
    intervention.value,
    deviceCategory
      .getBreadcrumb(intervention.value?.device_model?.device_category)
      .map(category => category.name)
      .join(' / '),
  )
})

const prepayedAmount = computed(() => {
  return intervention.value?.transactions?.reduce((acc, transaction) => addPrices(acc, transaction.amount), 0) || 0
})

const currentAmount = computed(() => {
  return validTransactions.value.reduce((acc, transaction) => addPrices(acc, transaction.amount), 0) || 0
})
// const payedAmount = computed(() => {
//   return prepayedAmount.value + currentAmount.value
// })
const remainingAmount = computed(() => {
  return substractPrices(invoiceDetails.value?.total, currentAmount.value, prepayedAmount.value)
})

const canAddTransation = computed(() => {
  return validTransactions.value.length === transactions.value.length
    && currentAmount.value < invoiceDetails.value?.total
})

watch([remainingAmount], () => {
  generateInvoice.value = remainingAmount.value <= 0
})

function addTransaction(type: 'bank' | 'user_credit', method?: PaymentMethod) {
  const total = substractPrices(invoiceDetails.value?.total, prepayedAmount.value)
  const amount = total ? substractPrices(total, currentAmount.value) : 0
  transactions.value.push({
    type,
    amount: type === 'user_credit' ? Math.min(amount, intervention.value?.customer?.credit || 0) : amount,
    payment_method: method ?? null,
  })
}

// const isPayed = computed(() => {
//   return intervention.value?.status === 'payed'
// })

// const selectedPaymentMethodId = ref<string>()
// const selectedPaymentMethod = computed(() => {
//   return paymentMethods.getById(selectedPaymentMethodId.value)
// })

async function pay() {
  if (pending.value || !intervention.value || (!currentAmount && remainingAmount)) {
    return
  }

  if (currentAmount.value > invoiceDetails.value?.total) {
    toaster.push({
      title: 'Erreur',
      color: 'danger',
      message: 'Le montant payé ne peut pas être supérieur au montant total de la facture',
      icon: 'ph:x-circle',
      closable: true,
    })
    return
  }

  // $posthog?.capture('intervention_payment', {
  //   payment_method: selectedPaymentMethod.value.label,
  // })

  pending.value = true

  try {
    const invoice = await validatePayment({
      intervention: intervention.value,
      transactions: validTransactions.value,
    })
    payementModal.success()
    toaster.push({
      title: 'Paiement validé',
      color: 'success',
      message: 'Le paiement a été enregistré avec succès',
      closable: true,
      actions: [
        {
          label: 'Imprimer',
          icon: 'ph:printer',
          props: {
            size: 'sm',
            onClick: () => {
              if (!invoice?.id || !intervention.value?.id)
                return
              const { printUrl } = usePrint({
                sandbox: false,
                delay: 1000,
              })
              toaster.clear()
              printUrl(`/invoice/${intervention.value.id}/${invoice.id}/print`)
            },
          },
        },
        {
          label: 'Ouvrir la facture',
          icon: 'ph:arrow-square-in',
          props: {
            size: 'sm',
            color: 'primary',
            variant: 'pastel',
            to: `/invoice/${intervention.value.id}/${invoice.id}/print`,
            target: '_blank',
            onClick: () => {
              toaster.clear()
            },
          },
        },
      ],
    }, {
      dismissible: false,
      duration: 5000,
    })
  }
  catch (error) {
    toaster.push({
      title: 'Erreur',
      color: 'danger',
      message: 'Une erreur est survenue lors de la validation du payement',
      icon: 'ph:x-circle',
      closable: true,
    })
    console.error(error)
    $sentry.captureException(error)
  }
  finally {
    pending.value = false
  }
}

async function prepayment() {
  if (pending.value || !intervention.value || !currentAmount.value) {
    return
  }

  if (currentAmount.value > invoiceDetails.value?.total) {
    toaster.push({
      title: 'Erreur',
      color: 'danger',
      message: 'Le montant payé ne peut pas être supérieur au montant total de la facture',
      icon: 'ph:x-circle',
      closable: true,
    })
    return
  }

  pending.value = true

  try {
    await $directus.request(createItems('transaction', validTransactions.value.map(transaction => ({
      intervention: intervention.value?.id,
      customer: intervention.value?.customer?.id,
      amount: transaction.amount,
      payment_method: transaction.payment_method?.id,
    }))))
    payementModal.success()
    toaster.push({
      title: 'Accompte validé',
      color: 'success',
      message: 'Le paiement d\'acompte a été enregistré avec succès',
      closable: true,
      actions: [
        {
          label: 'Éditer',
          icon: 'lucide:pen-line',
          props: {
            size: 'sm',
            onClick: () => {
              if (!intervention.value?.id)
                return
              toaster.clear()
              panels.push('intervention-edit', {
                interventionId: intervention.value?.id,
              })
            },
          },
        },
        route.path === '/'
          ? {
              label: 'Planifier',
              icon: 'ph:calendar-dots-duotone',
              props: {
                to: withQuery(`/agenda`, { id: intervention.value?.id }),
                size: 'sm',
                color: 'primary',
                variant: 'pastel',
                onClick: () => {
                  toaster.clear()
                },
              },
            }
          : {
              label: 'Kanban',
              icon: 'ph:square-half-duotone',
              props: {
                to: `/`,
                size: 'sm',
                color: 'primary',
                onClick: () => {
                  toaster.clear()
                },
              },
            },
      ],
    }, {
      dismissible: false,
      duration: 5000,
    })
  }
  catch (error) {
    toaster.push({
      title: 'Erreur',
      color: 'danger',
      message: 'Une erreur est survenue lors de la validation de l\'acompte',
      icon: 'ph:x-circle',
      closable: true,
    })
    console.error(error)
    $sentry.captureException(error)
  }
  finally {
    pending.value = false
  }
}
</script>

<template>
  <BaseModal size="xl" open>
    <template #header>
      
      <div class="flex w-full items-center justify-between border-b border-muted-200 p-2 dark:border-muted-700 lg:p-3">
        <div class="flex items-center gap-4">
          <h3
            class="text-lg font-medium leading-6 text-muted-900 dark:text-white"
          >
            Encaissement
          </h3>
          <BaseTag
            rounded="full"
            color="muted-contrast"
            class="m-0 -ml-1 inline-flex h-5 shrink-0 items-center py-0 font-mono !text-sm font-semibold"
          >
            {{ intervention?.id?.substring(0, 4) }}
          </BaseTag>
        </div>
        <BaseTooltip
          content="Annuler"
          placement="left"
          :offset="4"
        >
          <BaseButtonClose
            size="sm"
            @click="payementModal.cancel()"
          />
        </BaseTooltip>
      </div>
    </template>
    <div class="p-4 lg:p-6">
      <div v-if="interventionStatus === 'pending' || interventionStatus === 'idle'">
        Chargement...
      </div>
      <div v-if="interventionStatus === 'error'">
        Une erreur est survenue lors du chargement de l'intervention
      </div>
      <div v-else-if="interventionStatus === 'success'" class="max-h-[70dvh] overflow-scroll nui-slimscroll">
        <div class="flex gap-4 min-h-32 flex-col md:flex-row">
          <div class="md:w-3/5 space-y-2">
            <div v-for="(transaction, idx) in transactions" :key="idx">
              <BaseButtonGroup>
                <BaseButton
                  v-if="transaction.type === 'bank'"
                  disabled
                  color="muted"
                  class="!px-3"
                >
                  <Icon
                    v-if="transaction.payment_method?.icon"
                    :name="transaction.payment_method.icon"
                    class="size-4"
                  />
                  <span class="whitespace-nowrap">{{
                    transaction.payment_method?.label
                  }}</span>
                </BaseButton>
                <BaseButton
                  v-else
                  disabled
                  color="muted"
                  class="!px-3"
                >
                  <Icon
                    name="ph:user"
                    class="size-4 shrink-0"
                  />
                  <span class="whitespace-nowrap">Solde client</span>
                </BaseButton>
                <BaseInputNumber
                  :model-value="transactions[idx].amount"
                  :model-modifiers="{ lazy: true, raw: true }"
                  inputmode="numeric"
                  class="text-end"
                  :min="0"
                  @update:model-value="(value) => transactions[idx].amount = parseFloatInput(value, 0)"
                />
                <BaseButton class="!px-3" @click="transactions.splice(idx, 1)">
                  <Icon name="ph:x" />
                </BaseButton>
              </BaseButtonGroup>
            </div>
            <div>
              <BaseMessage v-if="!currentAmount && !remainingAmount">
                Aucun paiement nécessaire
              </BaseMessage>
              <BaseDropdown
                v-else
                :disabled="!canAddTransation || !availablePaymentMethods.length"
                :classes="{ wrapper: 'dropdown-full' }"
                fixed
                placement="bottom-start"
              >
                <template #button>
                  <BaseButton
                    :color="!transactions?.length ? 'primary' : 'default'"
                    :style="{
                      borderEndEndRadius: '0.375rem !important',
                      borderStartEndRadius: '0.375rem !important',
                      width: '100%',
                    }"
                    :class="!transactions?.length ? '!dark:text-primary-800 ' : ''"
                  >
                    <span>Ajouter {{ !transactions?.length ? 'un ' : 'un autre ' }} moyen de paiement</span>
                    <Icon
                      name="ph:caret-down"
                      class="size-4"
                    />
                  </BaseButton>
                </template>

                <BaseDropdownItem
                  v-for="method in availablePaymentMethods"
                  :key="method.id"
                  :title="method.label!"
                  class="outline outline-offset-2 outline-transparent"
                  @click="addTransaction('bank', method)"
                >
                  <template #start>
                    <Icon
                      :name="method.icon!"
                      class="me-2 block size-5"
                    />
                  </template>
                </BaseDropdownItem>

                <BaseDropdownItem
                  v-if="intervention?.customer?.credit && transactions?.every(transaction => transaction.type !== 'user_credit')"
                  title="Solde client"
                  class="outline outline-offset-2 outline-transparent"
                  @click="addTransaction('user_credit')"
                >
                  <template #start>
                    <Icon
                      name="ph:user"
                      class="me-2 block size-5"
                    />
                  </template>
                  <template #end>
                    <span class="text-success-500 font-semibold text-xs">{{ formatPriceDiff(intervention.customer.credit) }}</span>
                  </template>
                </BaseDropdownItem>
              </BaseDropdown>
            </div>
          </div>

          <div class="md:w-2/5 md:ps-4 md:border-s md:border-s-muted-200 flex flex-col">
            <div class="flex justify-between">
              <span>Sous-total TTC</span>
              <span class="">
                {{ formatPrice(invoiceDetails.total) }}
              </span>
            </div>

            <div v-if="prepayedAmount" class="flex justify-between">
              <span>Acompte</span>
              <span class="">
                {{ formatPrice(prepayedAmount) }}
              </span>
            </div>
            <div class="flex justify-between grow">
              <span>Total Payé</span>
              <span class="">
                {{ formatPrice(currentAmount) }}
              </span>
            </div>

            <div class="flex justify-between">
              <span class="">
                <BaseSwitchBall
                  v-model="generateInvoice" 
                  color="success" 
                  label="Restituer au client"
                  :disabled="remainingAmount > 0"
                />
              </span>
            </div>
          </div>
        </div>

        <div class="flex justify-end w-full items-center mt-8 gap-4">
          <div class="group">
            <span
              class="flex justify-end items-center gap-1 font-sans text-xs font-semibold uppercase leading-none tracking-wide text-muted-400"
            >
              <span>Reste à payer</span>
            </span>
            <span
              class="flex justify-end items-start gap-1 text-muted-900 dark:text-muted-200"
            >
              <span class="flex flex-col items-end gap-0">
                <span class="text-2xl font-semibold" :class="[remainingAmount < 0 && 'text-danger-500']">
                  {{ formatPrice(remainingAmount) }}
                </span>
              </span>
            </span>
          </div>

          <BaseButton v-if="generateInvoice" size="lg" color="success" :disabled="pending" @click="pay">
            Encaisser et Restituer
          </BaseButton>
          <BaseButton v-else-if="currentAmount" size="lg" color="primary" :disabled="pending" @click="prepayment">
            Enregistrer un acompte
          </BaseButton>
          <BaseButton v-else size="lg" color="muted">
            Encaisser et Restituer
          </BaseButton>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<style scoped>
.dropdown-full {
  width: 100%;
}
.dropdown-full:deep(.nui-menu) {
  width: 100%;
}
</style>
