<script setup lang="ts">
const { isSidebarCollapsed } = useLayoutContext()
</script>

<template>
  <button
    class="nui-focus relative z-[60] ml-[-36px] hidden size-10 scale-75 rounded-full bg-white text-muted-400 transition-colors duration-150 hover:bg-muted-50 focus:outline-none focus-visible:outline-2 dark:bg-muted-900 dark:hover:bg-muted-800 md:block"
    @click="isSidebarCollapsed = !isSidebarCollapsed"
  >
    <span class="sr-only">Open mobile menu</span>
    <div
      class="absolute left-1/2 top-1/2 block w-5 -translate-x-1/2 -translate-y-1/2"
    >
      <span
        aria-hidden="true"
        class="absolute block h-0.5 w-5 bg-current transition duration-500 ease-in-out"
        :class="!isSidebarCollapsed ? 'rotate-45' : '-translate-y-1.5'"
      />
      <span
        aria-hidden="true"
        class="absolute block h-0.5 w-5 bg-current transition duration-500 ease-in-out"
        :class="!isSidebarCollapsed ? 'opacity-0' : ''"
      />
      <span
        aria-hidden="true"
        class="absolute block h-0.5 w-5 bg-current transition duration-500 ease-in-out"
        :class="!isSidebarCollapsed ? '-rotate-45' : 'translate-y-1.5'"
      />
    </div>
  </button>
</template>
